import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import HeartQuote from '../components/HeartQuote';
import YouTube from '../components/YouTube';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Zelda: Breath of the Wild with CSS",
  "date": "2017-08-15",
  "promo": "grids",
  "description": "Recreate the awesome Zelda UI with CSS Grid, Flexbox, and other web goodies.",
  "color": "#00aee7"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Zelda: Breath of the Wild is absolutely `}<em parentName="p">{`gorgeous`}</em>{`. 5 years of work & polish went into that game and it shows. In this series we're going to build Link's awesome inventory UI using `}<a parentName="p" {...{
        "href": "http://gridcritters.com"
      }}>{`CSS Grid`}</a>{`, `}<a parentName="p" {...{
        "href": "http://flexboxzombies.com"
      }}>{`Flexbox`}</a>{`, and a bunch of other goodies from our UI grab bag.`}</p>
    <p>{`If you want to follow along you can clone `}<a parentName="p" {...{
        "href": "https://github.com/geddski/zelda-inventory"
      }}>{`the repo`}</a>{` and build it with me.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git clone https://github.com/geddski/zelda-inventory.git
`}</code></pre>
    <h2>{`Broad Strokes`}</h2>
    <p>{`In this video we'll look at what makes this UI so great, and paint the `}<a parentName="p" {...{
        "href": "/post/polish/"
      }}>{`broad strokes`}</a>{` with CSS Grid.`}</p>
    <YouTube id="7zBpd3wWsnU" mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      